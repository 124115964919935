import { Template } from "@/containers/root/components/templates";
import NetworkSection from "@/containers/root/components/organisms/networks";
import { Metadata } from "next";

export const metadata: Metadata = {
  title: "pSTAKE | Liquid Staking"
};

export default function Home() {
  return (
    <Template className="stake" title="Stake">
      <NetworkSection />
    </Template>
  );
}
