"use client";
import React from "react";

export const Template = ({
  children,
  className,
  title,
}: {
  children: React.ReactNode;
  className: string;
  title: string;
}) => {
  return <>{children}</>;
};
