"use client";
import React from "react";
import NetworkCards from "./cards";
import Tooltip from "rc-tooltip";
import { Icon } from "../../../../../components";
import { BUG_REPORT_URL } from "../../../../../constants/static";
import CustomImage from "@/components/molecules/image";

const socialList = [
  {
    url: "https://twitter.com/pStakeFinance",
    iconName: "twitter-logo",
    tooltip: "twitter"
  },
  {
    url: "https://t.me/pstakefinance",
    iconName: "telegram-plane",
    tooltip: "telegram"
  },
  {
    url: "https://blog.pstake.finance/",
    iconName: "medium-m",
    tooltip: "medium"
  },
  {
    url: "https://discord.com/invite/qXRmTTGcYD",
    iconName: "discord",
    tooltip: "discord"
  },
  {
    url: "https://pstake.finance",
    iconName: "globe",
    tooltip: "Website"
  },
  {
    url: BUG_REPORT_URL,
    iconName: "bug",
    tooltip: "Bug Report"
  },
  {
    url: "https://docs.pstake.finance/",
    iconName: "docs",
    tooltip: "Docs"
  }
];

const NetworkSection = () => {
  return (
    <div>
      <div className="pt-[40px] mb-[40px]">
        <div className={"flex px-[40px] md:block"}>
          <div className="w-[150px]">
            <CustomImage
              src={`/logo.svg`}
              alt={"logo"}
              width={124}
              height={31}
            />
          </div>
          <div className={"flex-1 justify-center mr-[150px] md:m-auto"}>
            <p
              className={
                "text-white-100 text-[36px] text-center font-semibold leading-normal mt-2 mb-12 md:text-[20px] md:mb-4"
              }
            >
              Stake and earn with
              <br /> your favorite blockchains
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center mb-6 md:block md:w-[400px] lg:ml-auto ml-[100px] md:mx-auto md:pl-[100px]">
          <div
            className={
              "flex items-center md:flex-1 md:mb-2 relative md:justify-start"
            }
          >
            <div
              className={
                "text-[#C7323880] -lg:absolute -lg:-left-[65px] -lg:-top-[38px] -lg:text-[52px] text-4 mr-4 font-bold"
              }
            >
              01
            </div>
            <div className={""}>
              <p className="text-white-100 font-medium">Stake</p>
              <p className="text-white-200 text-[12px]">
                Stake your PoS tokens to <br /> earn staking rewards.
              </p>
            </div>
          </div>
          <div
            className={`bg-[#ECECEC] w-[104px] h-[1px] mx-4 md:mx-2 md:hidden`}
          />
          <div
            className={
              "flex items-center md:flex-1 md:mb-2 relative md:justify-start"
            }
          >
            <div
              className={
                "text-[#C7323880] -lg:absolute -lg:-left-[75px] -lg:-top-[38px] -lg:text-[52px] text-4 mr-4 font-bold"
              }
            >
              02
            </div>
            <div className={""}>
              <p className="text-white-100 font-medium">Mint</p>
              <p className="text-white-200 text-[12px]">
                Mint stkTokens to unlock
                <br /> liquidity of staked assets
              </p>
            </div>
          </div>
          <div
            className={`bg-[#ECECEC] w-[104px] h-[1px] mx-4 md:mx-2 md:hidden `}
          />
          <div
            className={"flex items-center md:flex-1 relative md:justify-start"}
          >
            <div
              className={
                "text-[#C7323880] -lg:absolute -lg:-left-[75px] -lg:-top-[38px] -lg:text-[52px] text-4 mr-4 font-bold"
              }
            >
              03
            </div>
            <div className={""}>
              <p className="text-white-100 font-medium">DeFi</p>
              <p className="text-white-200 text-[12px]">
                Use stkTokens in
                <br /> DeFi to earn additional yield.
              </p>
            </div>
          </div>
        </div>
      </div>
      <NetworkCards />
      <div className={"my-6"}>
        <div className={`flex py-3 px-8 justify-center`}>
          {socialList.map((item, index) => (
            <Tooltip placement="bottom" overlay={item.tooltip} key={index}>
              <a
                href={item.url}
                rel="noopener noreferrer"
                className="mr-3"
                target="_blank"
              >
                <Icon
                  viewClass="fill-[#787878] !w-[14px]"
                  iconName={item.iconName}
                />
              </a>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NetworkSection;
