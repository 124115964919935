import React from "react";
import CustomImage from "@/components/molecules/image";
import Link from "next/link";
import { Cosmos } from "@/helpers/feature-flags";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

const NetworkCards = () => {
  const networkList = [
    {
      asset: "Cosmos",
      network: "cosmos",
      imageUrl: "/tokens/atom_coin.svg",
      imgBg: "bg-[#9196C0F0]",
      cardText:
        "Liquid Stake Cosmos assets on the underlying network to get the best of both worlds — Staking & DeFi and watch your stkTokens value grow.",
      apy: 1,
      supportedNetworkLogos: [
        "/tokens/stk_osmo.svg",
        "/tokens/stk_atom.svg",
        "/tokens/stk_dydx.svg",
        "/tokens/stk_stars.svg",
        "/tokens/stk_huahua.svg",
        "/tokens/stk_xprt.svg"
        // Cosmos.stkBLD ? "/tokens/stk_bld.svg" : null
      ],
      supportedNetworks: [
        "ATOM,",
        "OSMO,",
        "DYDX,",
        "STARS,",
        "HUAHUA,",
        "XPRT"
        // Cosmos.stkBLD ? "BLD" : null
        // env === "Testnet" ? "XPRT" : null
      ],
      buttonText: "Stake",
      buttonUrl: "/cosmos",
      lsmEnabled: true,
      launched: true
    },
    {
      asset: "BNB Chain",
      network: "binance",
      imgBg: "bg-[#D6AB24]",
      imageUrl: "/tokens/bnb_coin.svg",
      cardText:
        "Liquid Stake BNB to get the best of both worlds — Staking & DeFi.",
      apy: 4,
      supportedNetworks: ["BNB"],
      supportedNetworkLogos: ["/tokens/stk_bnb.svg"],
      buttonText: "Stake",
      buttonUrl: "/bnb",
      lsmEnabled: false,
      launched: true
    },
    {
      asset: "Ethereum",
      network: "ethereum",
      imageUrl: "/tokens/eth_coin.svg",
      imgBg: "bg-[#8570DB]",
      cardText:
        "Natively liquid stake ETH on Ethereum and supported L2s  — Arbitrum & Optimism.",
      apy: 12,
      supportedNetworkLogos: [
        "/tokens/stk_eth.svg",
        "/tokens/stketh_optimism.svg",
        "/tokens/stketh_arbitrum.svg"
      ],
      supportedNetworks: ["ETH"],
      buttonText:
        process.env.NEXT_PUBLIC_ENVIRONMENT === "Testnet"
          ? "Stake"
          : "Coming Soon",
      buttonUrl: "/eth",
      lsmEnabled: false,
      launched: false
    }
  ];

  const getNetworkEmptyList = (list: any[]) => {
    console.log(
      list,
      list.length,
      list.filter((item) => item === null).length,
      "getNetworkLength"
    );
    return list.filter((item) => item === null).length;
  };

  const getNetworkLength = (list: any[]) => {
    console.log(
      list,
      list.length,
      list.filter((item) => item !== null).length,
      "getNetworkLength"
    );

    list.map(
      (network, index) =>
        network !== null
          ? console.log(index, network, list.length, "in mapp")
          : ""
      // <p className="text-white-300 text-sm" key={index}>
      //   {network}
      //   {item.supportedNetworks.length > 1 &&
      //   index + 1 === getNetworkLength(item.supportedNetworks)
      //     ? ""
      //     : ","}
      //   &nbsp;
      // </p>
    );
    return list.length;
  };
  return (
    <div className="flex flex-wrap max-w-[1080px] mx-auto justify-center">
      {networkList.map((item, index) => (
        <div
          className={`-lg:basis-[35%] -lg:max-w-[35%] ${
            !item.launched ? "hidden" : "block"
          } px-4 mb-4 md:max-w-[100%] md:basis-full`}
          key={index}
        >
          <div className="bg-black-500 p-6 h-full flex justify-between flex-col rounded-md">
            <div className="mb-4">
              <div className={`${item.imgBg} rounded-md p-4 mb-3`}>
                <CustomImage
                  src={item.imageUrl}
                  alt={item.asset}
                  width={86}
                  height={86}
                  className="mx-auto mb-2"
                />
              </div>
              <div className={"mb-1 flex items-center"}>
                <h5 className="text-white-100 text-xl font-medium leading-normal ">
                  {item.asset}
                </h5>
              </div>
              <div className={"flex items-center mb-3 flex-wrap"}>
                {item.supportedNetworks.map((network, index) =>
                  network !== null ? (
                    <p className="text-white-300 text-sm" key={index}>
                      {network}
                      &nbsp;
                    </p>
                  ) : null
                )}
              </div>
              <p className="text-[#C9C9C9] text-[12px]">{item.cardText}</p>
            </div>
            <div>
              <div className={"flex items-center justify-start mb-4"}>
                {item.supportedNetworkLogos.map((network, index) =>
                  network !== null ? (
                    <CustomImage
                      key={index}
                      src={network}
                      alt={network}
                      width={32}
                      height={32}
                      className="mx-1"
                    />
                  ) : (
                    ""
                  )
                )}
              </div>
              <Link
                href={item.buttonUrl}
                as={item.buttonUrl}
                className={`text-center text-white-200 rounded-md bg-[#c73238]
                 py-[0.5rem] px-4 w-full block hover:bg-[#cb575bfa] ${
                   !item.launched ? "pointer-events-none opacity-50" : ""
                 }`}
              >
                {item.buttonText}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NetworkCards;
